import React, { useContext, useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import Card from "react-bootstrap/Card";
import ReviewCard from "./review-card";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const ReviewSection = ({ fromIndex, toIndex, black }) => {
	const data = useStaticQuery(
		graphql`
			{
				reviews {
					reviewCount
					reviews {
						author
						datePublished
						source
						reviewBody
					}
				}
			}
		`
	);

	const [reviewsTotal, setReviewsTotal] = useState([]);
	var reviews = data.reviews.reviews;
	var totalReviews = [];
	const responsive = {
		0: {
			items: 1,
		},
		767: {
			items: 2,
		},
		1200: {
			items: 3,
		},
	};

	const items = reviewsTotal
		.filter((review, index) => index >= fromIndex && index < toIndex)
		.map((review) => <ReviewCard black={black} review={review} />);

	const handleReviewsFirst = () => {
		totalReviews = totalReviews.concat(
			reviews.map((review) => ({
				reviewBody: review.reviewBody,
				datePublished: new Date(review.datePublished),
				author: review.author,
				source: review.source,
			}))
		);
		setReviewsTotal(
			totalReviews.sort((a, b) => b.datePublished - a.datePublished)
		);
	};

	var num = 0;

	useEffect(() => {
		handleReviewsFirst();
	}, [num]);

	return (
		<Container
			fluid={true}
			className={`  ${black ? "bg-black " : ""}`}
			as="section"
		>
			<Row className="justify-content-center">
				<Col xs={12}>
					<Row className="d-md-none px-0 mx-0" style={{ maxWidth: "100%" }}>
						<AliceCarousel
							infinite={true}
							responsive={responsive}
							mouseTracking
							items={items}
							autoPlay
							autoPlayInterval={2000}
						/>
					</Row>
					<Row className="d-none d-md-flex g-4" style={{ maxWidth: "100%" }}>
						{reviewsTotal
							.filter((review, index) => index >= fromIndex && index < toIndex)
							.map((review) => (
								<Col className=" " lg={4} md={6} xs={12}>
									<ReviewCard black={black} review={review} />
								</Col>
							))}
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default ReviewSection;
